<template>
  <div>
    <a-dropdown :trigger="['click']" @click.stop :visible="visible" @visibleChange="visibleChange">
      <div class="pointerArea" @click.stop="showMenu">
        <div class="statusPointer" :style="{ background: pointerColor }"></div>
      </div>
      <a-menu slot="overlay" v-if="menuShow">
        <a-menu-item
          class="status-item"
          @click="
            () =>
              handleTasks({
                label: 'STARTED'
              })
          "
          v-if="task.taskStatus == 'FINISHED'"
        >
          <div class="statusPointer" :style="{ background: 'red' }"></div>
          <span>可开始</span>
        </a-menu-item>
        <a-menu-item
          @click="
            () =>
              handleTasks({
                label: 'FINISHED'
              })
          "
          class="status-item"
          v-if="task.taskStatus !== 'FINISHED' && stage.stageName != '甲方反馈'"
        >
          <div class="statusPointer" :style="{ background: 'green' }"></div>
          <span>已完成</span>
        </a-menu-item>
        <a-menu-item
          @click="
            () =>
              handleTasks({
                label: 'FINISHED',
                hasFeedback: false
              })
          "
          class="status-item"
          v-if="task.taskStatus !== 'FINISHED' && stage.stageName == '甲方反馈' && !rejectShow && !task.hasFeedback"
        >
          <div style="texe-align:center;padding-left:10px">无反馈</div>
        </a-menu-item>
        <a-menu-item
          @click="() => (this.rejectShow = true)"
          class="status-item"
          v-if="task.taskStatus !== 'FINISHED' && stage.stageName == '甲方反馈' && !rejectShow"
        >
          <div style="texe-align:center;padding-left:10px">有反馈</div>
        </a-menu-item>
        <a-menu-item
          @click="
            () =>
              handleTasks({
                label: 'FINISHED',
                hasFeedback: true
              })
          "
          class="status-item"
          v-if="task.taskStatus !== 'FINISHED' && stage.stageName == '甲方反馈' && rejectShow"
        >
          <div class="statusPointer" :style="{ background: 'green' }"></div>
          <span>已完成</span>
        </a-menu-item>
        <template
          v-if="
            task.taskStatus !== 'FINISHED' &&
              task.taskStatus !== 'REJECTING' &&
              stage.stageName !== '给稿' &&
              rejectStages.length > 0 &&
              rejectShow
          "
        >
          <a-menu-item v-if="rejectStages.length > 1" v-for="(item, idx) in rejectStages" :key="'item' + idx">
            <a-checkbox
              v-model="item.checked"
              @change="
                e => {
                  onChange(e, item)
                }
              "
            >
              <a-dropdown :trigger="['hover']">
                <div style="line-height:30px;min-width: 100%;">驳回-{{ item.stageName }}</div>
                <div
                  slot="overlay"
                  style=" position: absolute; top: -4vh; left: 4.5vw;box-shadow: 0 2px 8px rgb(0 0 0 / 15%);"
                >
                  <a-menu>
                    <a-menu-item style="height:30px;line-height:30px;" @click="priorityChange(item, '高')" key="HIGH"
                      >高
                    </a-menu-item>
                    <a-menu-item
                      style="height:30px;line-height:30px;"
                      key="MIDDLE"
                      @click="priorityChange(item, '普通')"
                      >普通
                    </a-menu-item>
                    <a-menu-item style="height:30px;line-height:30px;" @click="priorityChange(item, '低')" key="LOW"
                      >低
                    </a-menu-item>
                  </a-menu>
                </div>
              </a-dropdown>
            </a-checkbox>
          </a-menu-item>

          <a-sub-menu v-else v-for="(item, idx) in rejectStages" :key="'submenu' + idx">
            <div
              slot="title"
              style="display:flex;align-items:center;line-height:30px;min-width: 100%;"
              @click="priorityChange(item, '普通')"
            >
              <div class="statusPointer" :style="{ background: statusColorMap.get('REJECTING') }"></div>
              <div style="margin-left: 14px;">驳回-{{ item.stageName }}</div>
            </div>
            <!-- <div slot="overlay"
                style=" position: absolute; top: -4vh; left: 4.5vw;box-shadow: 0 2px 8px rgb(0 0 0 / 15%);"> -->

            <a-menu-item style="line-height:30px;" @click="priorityChange(item, '高')" key="HIGH">高 </a-menu-item>
            <a-menu-item style="line-height:30px;" key="MIDDLE" @click="priorityChange(item, '普通')"
              >普通
            </a-menu-item>
            <a-menu-item style="line-height:30px;" @click="priorityChange(item, '低')" key="LOW">低 </a-menu-item>
          </a-sub-menu>
        </template>
        <a-menu-item
          v-if="
            rejectStages.length > 1 && task.taskStatus !== 'FINISHED' && task.taskStatus !== 'REJECTING' && rejectShow
          "
          style="background-color: #fff !important;"
        >
          <div class="task-btn">
            <div @click="onCancel" style="width:50%">
              取消
            </div>
            <div :style="`color:${color};width:50%`" @click="submitTask">确定</div>
          </div>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <tasks-model ref="tasksModel" @closeTasksModel="closeTasksModel" />
    <term-list-modal ref="termListModal" />
  </div>
</template>

<script>
import { getAction, postAction, deleteAction } from '@/api/manage'
import { upstatusDownloadFile } from '@/utils/util.js'
import termListModal from '@/components/termListModal'
import tasksModel from '@/views/system/modules/mytasks/modules/tasksModel'
import { checkPermission } from '@/utils/hasPermission'
import Vue from 'vue'
import { USER_INFO, ACCESS_TOKEN } from '@/store/mutation-types'
export default {
  components: {
    tasksModel,
    termListModal
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    chapter: {
      type: Object,
      default: () => ({})
    },
    stage: {
      type: Object,
      default: () => ({})
    },
    parent: {
      type: Object,
      default: () => ({})
    },
    loadData: {
      type: Function,
      default: () => {}
    },
    stages: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    pointerColor() {
      return this.statusColorMap.get(this.task.taskStatus)
    }
  },
  data() {
    return {
      statusColorMap: new Map([
        ['FINISHED', 'green'],
        ['STARTED', 'red'],
        ['WAITING', 'gray'],
        ['REJECTING', 'orange']
      ]),
      menuShow: true,
      visible: false,
      rejectStages: [],
      checkedList: [],
      userInfo: {},
      color: '',
      rejectShow: true
    }
  },
  methods: {
    onCancel() {
      this.visible = !this.visible
    },
    checkPermission() {
      return checkPermission('production:task:button:change') || (this.task && this.userInfo.id == this.task.ownerId)
    },
    getStatusLabel(value) {
      let text = ''
      switch (value) {
        case 'WAITING':
          text = '等待'
          break
        case 'STARTED':
          text = '可开始'
          break
        case 'REJECTING':
          text = '驳回'
          break
        case 'FINISHED':
          text = '已完成'
          break
      }
      return text
    },
    priorityChange(item, type) {
      if (this.rejectStages.length == 1) {
        this.rejectStages[0].stageName = this.rejectStages[0].stageName.split('-')[0] + '-' + type
        this.checkedList = this.rejectStages
        this.submitTask()
      }
      item.stageName = item.stageName.split('-')[0] + '-' + type
      item.checked = true
      if (
        this.checkedList.every(i => {
          return i.stageId != item.stageId
        })
      ) {
        this.checkedList.push(item)
      }
    },
    visibleChange() {
      setTimeout(() => {
        this.visible = !this.visible
      }, 100)
    },
    onChange(e, item) {
      if (
        e.target.checked &&
        this.checkedList.every(i => {
          return i.stageId != item.stageId
        })
      ) {
        this.checkedList.push(item)
      } else if (
        !e.target.checked &&
        this.checkedList.some(i => {
          return i.stageId == item.stageId
        })
      ) {
        this.checkedList = this.checkedList.filter(i => {
          return i.stageId != item.stageId
        })
      }
    },
    showMenu(e) {
      this.rejectShow = this.stage.stageName == '甲方反馈' ? false : true
      console.log('showMenu', this.isTongChou, { e })
      /* const tasks = this.stages?.map(s => ({ id: s.id, ...this.chapter.stageIdTask[s.stageId] })) */
      if (
        !this.chapter.stageIdTask[this.stage.stageId].rejectTaskId &&
        this.chapter.stageIdTask[this.stage.stageId].taskStatus === 'REJECTING'
      )
        this.menuShow = false
      this.getRejectStages()
      if ((this.task.taskStatus == 'WAITING' && this.stage.stageName != '给稿') || !this.checkPermission()) {
        this.menuShow = false
        return
      }
      if (this.task.taskStatus === 'FINISHED' && !this.parent.isTongChou) {
        this.menuShow = false
        return
      }
    },
    async handleTasks(item) {
      console.log(item,this.task)
      this.visible = !this.visible
      const { label } = item
      const { taskId, taskStatus } = this.task
      const { stageName } = this.stage
      const id = this.stage.id
      if (stageName !== '给稿' && taskStatus === 'WAITING') {
        this.$message.info({
          content: '等待状态不能点击已完成'
        })
        return
      }

      if (taskStatus === 'FINISHED') {
        getAction('/tasks/resetTasks?taskId=' + taskId).then(res => {
          this.loadData()
          this.$message.success('可开始')
        })
        return
      }

      if (taskStatus === label) {
        this.$message.info({
          content: '已经是' + this.getStatusLabel(label) + '状态了'
        })
        return
      }

      let url = ''
      if (label === 'STARTED') {
        url = `/tasks/start/${taskId}`
      } else if (label === 'FINISHED') {
        let arr = []
        for (let key in this.chapter.stageIdTask) {
          arr.push({ ...this.chapter.stageIdTask[key], stageId: key })
        }
        if (
          !arr
            ?.filter(item => {
              return item.taskId
            })
            .every(item => {
              return item.deadline
            })
        ) {
          this.$message.error('请将所有任务设置截止日，再点击已完成')
          return
        }
        let hasFeedback = item.hasFeedback === undefined ? '' : item.hasFeedback ? true : false
        url = `/tasks/complete?taskId=${taskId}&hasFeedback=${hasFeedback}`
        let that = this
        if (item.hasFeedback === false) {
          this.$confirm({
            content: h => <div>请确认是否真的没有反馈</div>,
            okText: '是',
            cancelText: '否',
            async onOk() {
              const response = await getAction('/tasks/checkTermsStatus?taskIds=' + taskId)
              if (response.code == 200) {
                if (response.data.productionList && response.data.productionList.length) {
                  that.$refs.termListModal.open(response.data.productionList)
                } else {
                  getAction(url).then(async res => {
                    if (res.success) {
                      that.rejectStages = []
                      upstatusDownloadFile([taskId])
                      if (res.data.noUpdateTasks.length) {
                        that.$refs.tasksModel.open(res.data.noUpdateTasks, hasFeedback)
                      } else {
                        that.$message.success('已完成')
                        that.loadData(res.data.updatedTasks)
                      }
                    }
                    if (res.code === 510) {
                      that.$message.warning(res.message)
                    } else if (res.code == 500) {
                      that.$message.error(res.msg || res.message)
                    }
                  })
                }
              }
            },
            onCancel() {},
            class: ''
          })
          return
        }
      } else if (this.getStatusLabel(label) == '驳回') {
        let hasFeedback = ''
        if (stageName == '甲方反馈') {
          hasFeedback = true
        }
        postAction('/tasks/reject', { taskId, targetList: item.targetList, hasFeedback }).then(res => {
          if (res.code != 500) {
            this.$message.success('驳回成功')
            this.rejectStages = []
            this.loadData()
          } else {
            this.$message.error(res.message)
          }
        })
        return
      }
      if (this.getStatusLabel(label) != '已完成') {
        getAction(url).then(res => {
          if (res.success) {
            this.$message.success(this.getStatusLabel(label))
            this.rejectStages = []
            this.loadData()
          }
          if (res.code === 510) {
            this.$message.warning(res.message)
          } else if (res.code == 500) {
            this.$message.error(res.msg || res.message)
          }
        })
      } else {
        let hasFeedback = item.hasFeedback === undefined ? '' : item.hasFeedback ? true : false
        if (this.getStatusLabel(label) == '已完成') {
          if (
            this.task.useTranslationSystem &&
            (this.task.stageName == '翻译' || this.task.stageName == '校对' || this.task.stageName == '终审')
          ) {
            this.$confirm({
              content: h => (
                <div>
                  请确认当前任务的工作已经全部完成，提前点完成/未完成点完成均会影响后续考核。
                </div>
              ),
              okText: '是',
              cancelText: '否',
              onOk: async () => {
                const response = await getAction('/tasks/checkTermsStatus?taskIds=' + taskId)
                if (response.code == 200) {
                  if (response.data.productionList && response.data.productionList.length) {
                    this.$refs.termListModal.open(response.data.productionList)
                  } else {
                    getAction(url).then(async res => {
                      if (res.success) {
                        this.rejectStages = []
                        upstatusDownloadFile([taskId])
                        if (res.data.noUpdateTasks.length) {
                          this.$refs.tasksModel.open(res.data.noUpdateTasks, hasFeedback)
                        } else {
                          this.$message.success(this.getStatusLabel(label))
                          this.loadData()
                        }
                      }
                      if (res.code === 510) {
                        this.$message.warning(res.message)
                      } else if (res.code == 500) {
                        this.$message.error(res.msg || res.message)
                      }
                    })
                  }
                }
              }
            })
          } else {
            const response = await getAction('/tasks/checkTermsStatus?taskIds=' + taskId)
            if (response.code == 200) {
              if (response.data.productionList && response.data.productionList.length) {
                this.$refs.termListModal.open(response.data.productionList)
              } else {
                getAction(url).then(async res => {
                  if (res.success) {
                    this.rejectStages = []
                    upstatusDownloadFile([taskId])
                    if (res.data.noUpdateTasks.length) {
                      this.$refs.tasksModel.open(res.data.noUpdateTasks, hasFeedback)
                    } else {
                      this.$message.success(this.getStatusLabel(label))
                      this.loadData()
                    }
                  }
                  if (res.code === 510) {
                    this.$message.warning(res.message)
                  } else if (res.code == 500) {
                    this.$message.error(res.msg || res.message)
                  }
                })
              }
            }
          }
        } else {
          getAction(url).then(async res => {
            if (res.success) {
              this.rejectStages = []
              upstatusDownloadFile([taskId])
              if (res.data.noUpdateTasks.length) {
                this.$refs.tasksModel.open(res.data.noUpdateTasks, hasFeedback)
              } else {
                this.$message.success(this.getStatusLabel(label))
                this.loadData()
              }
            }
            if (res.code === 510) {
              this.$message.warning(res.message)
            } else if (res.code == 500) {
              this.$message.error(res.msg || res.message)
            }
          })
        }
      }
    },
    closeTasksModel(data) {
      this.loadData()
    },
    submitTask() {
      let targetList = []
      this.checkedList?.forEach(item => {
        if (item.stageName.split('-').length > 1) {
          targetList.push({
            stageId: item.stageId,
            priority:
              item.stageName.split('-')[1] == '高'
                ? 'HIGH'
                : item.stageName.split('-')[1] == '普通'
                ? 'MIDDLE'
                : item.stageName.split('-')[1] == '低'
                ? 'LOW'
                : ''
          })
        } else {
          targetList.push({ stageId: item.stageId, priority: 'MIDDLE' })
        }
      })
      if (!this.checkedList?.length) return
      this.handleTasks({ label: 'REJECTING', targetList })
    },
    async getRejectStages() {
      const res = await getAction('/production/canRejectStage', {
        stageId: this.stage.stageId,
        productId: this.parent.productionId
      })
      try {
        if (res.code == 200) {
          res.data.forEach(item => {
            item.checked = false
          })
          this.rejectStages = res.data
        } else {
          this.$message.error(res.msg || res.message)
        }
      } catch (err) {
        this.$message.error(err)
      }
    }
  },
  mounted() {
    this.color = this.$store.state.app.color
    this.userInfo = Vue.ls.get(USER_INFO)
    this.rejectShow = this.stage.stageName == '甲方反馈' ? false : true
  }
}
</script>

<style scoped lang="less">
.pointerArea {
  padding: 4px;
  cursor: pointer;
}

.statusPointer {
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.status-item {
  display: flex;
  min-width: 85px;
  align-items: center;

  > span {
    margin-left: 14px;
  }
}

/deep/ .ant-dropdown-menu-submenu-arrow {
  display: none;
}
</style>
