var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-spin",
    { style: _vm.backCss, attrs: { spinning: _vm.loading } },
    [
      _c("div", { staticClass: "taskBoard" }, [
        _c(
          "div",
          {
            staticClass: "stageTitles",
            style: { minWidth: _vm.stages.length * 180 + "px" },
          },
          _vm._l(_vm.stagesCopy, function (stage) {
            return _c(
              "div",
              {
                key: stage.stageId,
                staticClass: "stageTitle",
                style: { opacity: stage.status == "1" ? "0.4" : "" },
              },
              [_vm._v("\n        " + _vm._s(stage.stageName) + "\n      ")]
            )
          }),
          0
        ),
        _c(
          "div",
          {
            staticClass: "boardContent",
            style: { minWidth: _vm.stages.length * 180 + "px" },
          },
          [
            _c("div", {
              staticClass: "virtual-top",
              style: "height:" + _vm.topLength + "px",
            }),
            _vm._l(_vm.copyChapters, function (chapter) {
              return _c(
                "div",
                {
                  key: chapter.chapterId,
                  staticClass: "boardChapter chapter-li",
                },
                _vm._l(_vm.stagesCopy, function (stage) {
                  return _c(
                    "div",
                    { key: stage.stageId, staticClass: "boardStage" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "board-shadow",
                          style:
                            chapter.stageIdTask &&
                            chapter.stageIdTask[stage.stageId] &&
                            chapter.stageIdTask[stage.stageId].isSelect
                              ? "box-shadow: 5px 5px 10px rgb(0 0 0 / 10%), -1px 0 1px rgb(0 0 0 / 6%);"
                              : "",
                        },
                        [
                          _vm.getTask(chapter, stage).taskId &&
                          _vm.filterCondition(_vm.getTask(chapter, stage))
                            ? _c(
                                "div",
                                {
                                  class:
                                    chapter.stageIdTask &&
                                    chapter.stageIdTask[stage.stageId] &&
                                    chapter.stageIdTask[stage.stageId].isSelect
                                      ? "boardTask boardTask-select"
                                      : "boardTask ",
                                  style: _vm.getTaskStyle(
                                    _vm.getTask(chapter, stage),
                                    chapter
                                  ),
                                  on: {
                                    click: function ($event) {
                                      _vm.selectChange(
                                        _vm.getTask(chapter, stage),
                                        stage,
                                        _vm.stages,
                                        chapter.stageIdTask[stage.stageId]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "boardProduct" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-omit-one-line productTitle kanban-span",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              _vm.gotoDetail(
                                                _vm.getTask(chapter, stage)
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.parent.productionName
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _c("StatusPointer", {
                                        staticStyle: { "margin-top": "5px" },
                                        attrs: {
                                          loadData: _vm.reloadData,
                                          parent: _vm.parent,
                                          stage: stage,
                                          chapter: chapter,
                                          stages: _vm.stages,
                                          task: _vm.getTask(chapter, stage),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "boardChapter kanban-span" },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(chapter.sequenceNo)),
                                      ]),
                                    ]
                                  ),
                                  _vm.getIsEdit(chapter, stage)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "boardUser kanban-span",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "a-select",
                                            {
                                              staticStyle: {
                                                "margin-top": "10px",
                                                width: "97%",
                                              },
                                              attrs: {
                                                showArrow: "",
                                                placeholder: "选择用户",
                                                defaultValue:
                                                  chapter.stageIdTask &&
                                                  chapter.stageIdTask[
                                                    stage.stageId
                                                  ].ownerId,
                                              },
                                              on: {
                                                change: (e) => {
                                                  _vm.userSelectChange(
                                                    e,
                                                    _vm.getTask(chapter, stage)
                                                  )
                                                },
                                              },
                                            },
                                            _vm._l(
                                              stage.users,
                                              function (user) {
                                                return _c(
                                                  "a-select-option",
                                                  {
                                                    key: user.userId,
                                                    attrs: {
                                                      value: user.userId,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(user.userName) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass: "boardUser kanban-span",
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "boardAvatar",
                                            attrs: {
                                              src: _vm.getTask(chapter, stage)
                                                .avatar,
                                            },
                                          }),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getTask(chapter, stage)
                                                  .userName
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                  _vm.getIsEdit(chapter, stage)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "boardDeadline",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                          },
                                        },
                                        [
                                          _c("a-date-picker", {
                                            staticClass: "kanban-date",
                                            staticStyle: {
                                              "font-size": "12px",
                                              "margin-top": "10px",
                                            },
                                            attrs: {
                                              format: "YYYY-MM-DD HH:mm",
                                              valueFormat: "YYYY-MM-DD HH:mm",
                                              "show-time": {
                                                format: "HH:mm",
                                                defaultValue: _vm.moment(
                                                  "23:59",
                                                  "HH:mm"
                                                ),
                                              },
                                              placeholder: "请选择截止日期",
                                              defaultValue: _vm.getTask(
                                                chapter,
                                                stage
                                              ).deadline,
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                              },
                                              change: (e) => {
                                                _vm.dateChange(
                                                  e,
                                                  _vm.getTask(chapter, stage)
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : !_vm.getIsEdit(chapter, stage) &&
                                      _vm.getTask(chapter, stage).deadline
                                    ? _c(
                                        "div",
                                        { staticClass: "boardDeadline" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              style: {
                                                color:
                                                  _vm.getTask(chapter, stage)
                                                    .warnStatus == "DELAY"
                                                    ? "red"
                                                    : null,
                                                lineHeight: "24px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm
                                                      .getTask(chapter, stage)
                                                      .deadline.substring(2, 16)
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "more-icon",
                                              style: _vm.backCss,
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                },
                                              },
                                            },
                                            [
                                              (((_vm.getTask(chapter, stage)
                                                .taskStatus != "WAITING" &&
                                                _vm.getTask(chapter, stage)
                                                  .taskStatus != "REJECTING") ||
                                                (_vm.getTask(chapter, stage)
                                                  .taskStatus == "REJECTING" &&
                                                  _vm.getTask(chapter, stage)
                                                    .rejectTaskId)) &&
                                                (_vm.getTask(chapter, stage)
                                                  .applyTranslation ||
                                                  _vm.getTask(chapter, stage)
                                                    .applyReview)) ||
                                              (_vm.getTask(chapter, stage)
                                                .applyReview &&
                                                (_vm.getTask(chapter, stage)
                                                  .stageName == "制作" ||
                                                  _vm.getTask(chapter, stage)
                                                    .stageName == "制作修改" ||
                                                  _vm.getTask(chapter, stage)
                                                    .stageName == "监制" ||
                                                  _vm.getTask(chapter, stage)
                                                    .stageName ==
                                                    "监制确认")) ||
                                              _vm.getTask(chapter, stage)
                                                .stageName == "给稿" ||
                                              (_vm.getTask(chapter, stage)
                                                .applyTranslation &&
                                                _vm.getTask(chapter, stage)
                                                  .stageName == "制作") ||
                                              (_vm.getTask(chapter, stage)
                                                .applyReview &&
                                                (_vm.getTask(chapter, stage)
                                                  .stageName == "监制确认" ||
                                                  _vm.getTask(chapter, stage)
                                                    .stageName == "监制" ||
                                                  _vm.getTask(chapter, stage)
                                                    .stageName == "交稿")) ||
                                              (_vm.getTask(chapter, stage)
                                                .applyTranslation &&
                                                _vm.getTask(chapter, stage)
                                                  .stageName == "交稿")
                                                ? _c(
                                                    "a-dropdown",
                                                    {
                                                      attrs: {
                                                        trigger: ["click"],
                                                      },
                                                    },
                                                    [
                                                      _c("a-icon", {
                                                        staticClass:
                                                          "more-icon",
                                                        staticStyle: {
                                                          position: "relative",
                                                          left: "-7px",
                                                          top: "2px",
                                                          "font-weight": "bold",
                                                          color: "black",
                                                        },
                                                        attrs: { type: "dash" },
                                                      }),
                                                      _c(
                                                        "a-menu",
                                                        {
                                                          attrs: {
                                                            slot: "overlay",
                                                          },
                                                          slot: "overlay",
                                                        },
                                                        [
                                                          ((_vm.getTask(
                                                            chapter,
                                                            stage
                                                          ).taskStatus !=
                                                            "WAITING" &&
                                                            _vm.getTask(
                                                              chapter,
                                                              stage
                                                            ).taskStatus !=
                                                              "REJECTING") ||
                                                            (_vm.getTask(
                                                              chapter,
                                                              stage
                                                            ).taskStatus ==
                                                              "REJECTING" &&
                                                              _vm.getTask(
                                                                chapter,
                                                                stage
                                                              )
                                                                .rejectTaskId)) &&
                                                          _vm.getTask(
                                                            chapter,
                                                            stage
                                                          ).applyTranslation
                                                            ? _c(
                                                                "a-menu-item",
                                                                {
                                                                  key: "1",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.goToTranslate(
                                                                          _vm.getTask(
                                                                            chapter,
                                                                            stage
                                                                          )
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "跳转翻译系统"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          ((_vm.getTask(
                                                            chapter,
                                                            stage
                                                          ).taskStatus !=
                                                            "WAITING" &&
                                                            _vm.getTask(
                                                              chapter,
                                                              stage
                                                            ).taskStatus !=
                                                              "REJECTING") ||
                                                            (_vm.getTask(
                                                              chapter,
                                                              stage
                                                            ).taskStatus ==
                                                              "REJECTING" &&
                                                              _vm.getTask(
                                                                chapter,
                                                                stage
                                                              )
                                                                .rejectTaskId)) &&
                                                          _vm.getTask(
                                                            chapter,
                                                            stage
                                                          ).applyReview
                                                            ? _c(
                                                                "a-menu-item",
                                                                {
                                                                  key: "2",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.goToFatchStraw(
                                                                          _vm.getTask(
                                                                            chapter,
                                                                            stage
                                                                          )
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "跳转查稿系统"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.getTask(
                                                            chapter,
                                                            stage
                                                          ).stageName == "给稿"
                                                            ? _c(
                                                                "a-menu-item",
                                                                {
                                                                  key: "3",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.goToUpload(
                                                                          _vm.getTask(
                                                                            chapter,
                                                                            stage
                                                                          )
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "上传原稿"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.getTask(
                                                            chapter,
                                                            stage
                                                          ).applyReview &&
                                                          (_vm.getTask(
                                                            chapter,
                                                            stage
                                                          ).stageName ==
                                                            "制作" ||
                                                            _vm.getTask(
                                                              chapter,
                                                              stage
                                                            ).stageName ==
                                                              "制作修改" ||
                                                            _vm.getTask(
                                                              chapter,
                                                              stage
                                                            ).stageName ==
                                                              "监制" ||
                                                            _vm.getTask(
                                                              chapter,
                                                              stage
                                                            ).stageName ==
                                                              "监制确认" ||
                                                            _vm
                                                              .getTask(
                                                                chapter,
                                                                stage
                                                              )
                                                              .stageName.indexOf(
                                                                "建模"
                                                              ) > -1 ||
                                                            _vm
                                                              .getTask(
                                                                chapter,
                                                                stage
                                                              )
                                                              .stageName.indexOf(
                                                                "精草"
                                                              ) > -1 ||
                                                            _vm
                                                              .getTask(
                                                                chapter,
                                                                stage
                                                              )
                                                              .stageName.indexOf(
                                                                "勾线"
                                                              ) > -1 ||
                                                            _vm
                                                              .getTask(
                                                                chapter,
                                                                stage
                                                              )
                                                              .stageName.indexOf(
                                                                "上色"
                                                              ) > -1 ||
                                                            _vm
                                                              .getTask(
                                                                chapter,
                                                                stage
                                                              )
                                                              .stageName.indexOf(
                                                                "后期"
                                                              ) > -1)
                                                            ? _c(
                                                                "a-menu-item",
                                                                {
                                                                  key: "4",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.gotoSumbmit(
                                                                          _vm.getTask(
                                                                            chapter,
                                                                            stage
                                                                          )
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "上传完成稿"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm
                                                            .getTask(
                                                              chapter,
                                                              stage
                                                            )
                                                            .systemTypes.some(
                                                              (item) =>
                                                                item ==
                                                                "IMAGE_TRANSLATION_SYSTEM"
                                                            ) &&
                                                          _vm.getTask(
                                                            chapter,
                                                            stage
                                                          ).stageName == "制作"
                                                            ? _c(
                                                                "a-menu-item",
                                                                {
                                                                  key: "5",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.downloadMake(
                                                                          _vm.getTask(
                                                                            chapter,
                                                                            stage
                                                                          )
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "下载制作稿"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.getTask(
                                                            chapter,
                                                            stage
                                                          ).applyReview &&
                                                          (_vm.getTask(
                                                            chapter,
                                                            stage
                                                          ).stageName ==
                                                            "监制确认" ||
                                                            _vm.getTask(
                                                              chapter,
                                                              stage
                                                            ).stageName ==
                                                              "监制" ||
                                                            _vm.getTask(
                                                              chapter,
                                                              stage
                                                            ).stageName ==
                                                              "交稿" ||
                                                            _vm.getTask(
                                                              chapter,
                                                              stage
                                                            ).stageName ==
                                                              "勾线终审" ||
                                                            _vm.getTask(
                                                              chapter,
                                                              stage
                                                            ).stageName ==
                                                              "上色终审")
                                                            ? _c(
                                                                "a-menu-item",
                                                                {
                                                                  key: "6",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.downloadOver(
                                                                          _vm.getTask(
                                                                            chapter,
                                                                            stage
                                                                          ),
                                                                          false
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "下载终稿"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.getTask(
                                                            chapter,
                                                            stage
                                                          ).applyReview &&
                                                          (_vm.getTask(
                                                            chapter,
                                                            stage
                                                          ).stageName ==
                                                            "监制确认" ||
                                                            _vm.getTask(
                                                              chapter,
                                                              stage
                                                            ).stageName ==
                                                              "监制" ||
                                                            _vm.getTask(
                                                              chapter,
                                                              stage
                                                            ).stageName ==
                                                              "交稿")
                                                            ? _c(
                                                                "a-menu-item",
                                                                {
                                                                  key: "7",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.downloadOver(
                                                                          _vm.getTask(
                                                                            chapter,
                                                                            stage
                                                                          ),
                                                                          true
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "下载切片"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.getTask(
                                                            chapter,
                                                            stage
                                                          ).applyTranslation &&
                                                          _vm.getTask(
                                                            chapter,
                                                            stage
                                                          ).stageName == "交稿"
                                                            ? _c(
                                                                "a-menu-item",
                                                                {
                                                                  key: "8",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.downloadJpg(
                                                                          _vm.getTask(
                                                                            chapter,
                                                                            stage
                                                                          )
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "下载翻译JPG"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.getTask(
                                                            chapter,
                                                            stage
                                                          ).applyTranslation &&
                                                          _vm.getTask(
                                                            chapter,
                                                            stage
                                                          ).stageName == "交稿"
                                                            ? _c(
                                                                "a-menu-item",
                                                                {
                                                                  key: "9",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.downloadPdf(
                                                                          _vm.getTask(
                                                                            chapter,
                                                                            stage
                                                                          )
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "下载翻译PDF"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            }),
            _c("div", {
              staticClass: "virtual-bottom",
              style: "height:" + _vm.bottomLength + "px",
            }),
          ],
          2
        ),
      ]),
      _c(
        "a-modal",
        {
          attrs: { title: "请更换人员", width: "350px", visible: _vm.visible },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
        },
        [
          _c(
            "a-select",
            {
              staticStyle: { width: "250px" },
              attrs: {
                showArrow: "",
                placeholder: "选择用户",
                "option-filter-prop": "label",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
              model: {
                value: _vm.ownerId,
                callback: function ($$v) {
                  _vm.ownerId = $$v
                },
                expression: "ownerId",
              },
            },
            _vm._l(_vm.selectUsers, function (user) {
              return _c(
                "a-select-option",
                {
                  key: user.userId,
                  attrs: { label: user.userName, value: user.userId },
                },
                [
                  _c("img", {
                    staticStyle: {
                      width: "25px",
                      height: "25px",
                      "border-radius": "50%",
                      "margin-right": "10px",
                      position: "relative",
                      top: "-2px",
                    },
                    attrs: { src: user.avatar, alt: "" },
                  }),
                  _c("span", [_vm._v(_vm._s(user.userName))]),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            visible: _vm.confirmVisible,
            wrapClassName: "confirm-modal",
            bodyStyle: { padding: "32px", paddingBottom: "24px" },
            width: "416px",
            footer: null,
          },
          on: {
            cancel: function ($event) {
              _vm.confirmVisible = false
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "16px",
                display: "flex",
                "align-items": "center",
                "font-weight": "500",
                color: "rgba(0, 0, 0, 0.85)",
              },
            },
            [
              _c("a-icon", {
                staticStyle: {
                  "font-size": "22px",
                  "margin-right": "15px",
                  color: "orange",
                },
                attrs: { type: "question-circle" },
              }),
              _vm._v("是否加载已锁定状态的文件?\n    "),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                height: "62px",
                display: "flex",
                "align-items": "end",
                "justify-content": "end",
              },
            },
            [
              _c(
                "a-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleConfimCancel(_vm.confirmTask)
                    },
                  },
                },
                [_vm._v("\n        不加载\n      ")]
              ),
              _c(
                "a-button",
                {
                  staticStyle: { "margin-left": "8px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleConfimOk(_vm.confirmTask)
                    },
                  },
                },
                [_vm._v("\n        加载\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }