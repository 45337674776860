var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-dropdown",
        {
          attrs: { trigger: ["click"], visible: _vm.visible },
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
            visibleChange: _vm.visibleChange,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "pointerArea",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.showMenu.apply(null, arguments)
                },
              },
            },
            [
              _c("div", {
                staticClass: "statusPointer",
                style: { background: _vm.pointerColor },
              }),
            ]
          ),
          _vm.menuShow
            ? _c(
                "a-menu",
                { attrs: { slot: "overlay" }, slot: "overlay" },
                [
                  _vm.task.taskStatus == "FINISHED"
                    ? _c(
                        "a-menu-item",
                        {
                          staticClass: "status-item",
                          on: {
                            click: () =>
                              _vm.handleTasks({
                                label: "STARTED",
                              }),
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "statusPointer",
                            style: { background: "red" },
                          }),
                          _c("span", [_vm._v("可开始")]),
                        ]
                      )
                    : _vm._e(),
                  _vm.task.taskStatus !== "FINISHED" &&
                  _vm.stage.stageName != "甲方反馈"
                    ? _c(
                        "a-menu-item",
                        {
                          staticClass: "status-item",
                          on: {
                            click: () =>
                              _vm.handleTasks({
                                label: "FINISHED",
                              }),
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "statusPointer",
                            style: { background: "green" },
                          }),
                          _c("span", [_vm._v("已完成")]),
                        ]
                      )
                    : _vm._e(),
                  _vm.task.taskStatus !== "FINISHED" &&
                  _vm.stage.stageName == "甲方反馈" &&
                  !_vm.rejectShow &&
                  !_vm.task.hasFeedback
                    ? _c(
                        "a-menu-item",
                        {
                          staticClass: "status-item",
                          on: {
                            click: () =>
                              _vm.handleTasks({
                                label: "FINISHED",
                                hasFeedback: false,
                              }),
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "texe-align": "center",
                                "padding-left": "10px",
                              },
                            },
                            [_vm._v("无反馈")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.task.taskStatus !== "FINISHED" &&
                  _vm.stage.stageName == "甲方反馈" &&
                  !_vm.rejectShow
                    ? _c(
                        "a-menu-item",
                        {
                          staticClass: "status-item",
                          on: { click: () => (this.rejectShow = true) },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "texe-align": "center",
                                "padding-left": "10px",
                              },
                            },
                            [_vm._v("有反馈")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.task.taskStatus !== "FINISHED" &&
                  _vm.stage.stageName == "甲方反馈" &&
                  _vm.rejectShow
                    ? _c(
                        "a-menu-item",
                        {
                          staticClass: "status-item",
                          on: {
                            click: () =>
                              _vm.handleTasks({
                                label: "FINISHED",
                                hasFeedback: true,
                              }),
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "statusPointer",
                            style: { background: "green" },
                          }),
                          _c("span", [_vm._v("已完成")]),
                        ]
                      )
                    : _vm._e(),
                  _vm.task.taskStatus !== "FINISHED" &&
                  _vm.task.taskStatus !== "REJECTING" &&
                  _vm.stage.stageName !== "给稿" &&
                  _vm.rejectStages.length > 0 &&
                  _vm.rejectShow
                    ? _vm._l(_vm.rejectStages, function (item, idx) {
                        return _vm.rejectStages.length > 1
                          ? _c(
                              "a-menu-item",
                              { key: "item" + idx },
                              [
                                _c(
                                  "a-checkbox",
                                  {
                                    on: {
                                      change: (e) => {
                                        _vm.onChange(e, item)
                                      },
                                    },
                                    model: {
                                      value: item.checked,
                                      callback: function ($$v) {
                                        _vm.$set(item, "checked", $$v)
                                      },
                                      expression: "item.checked",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-dropdown",
                                      { attrs: { trigger: ["hover"] } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "line-height": "30px",
                                              "min-width": "100%",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "驳回-" + _vm._s(item.stageName)
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              position: "absolute",
                                              top: "-4vh",
                                              left: "4.5vw",
                                              "box-shadow":
                                                "0 2px 8px rgb(0 0 0 / 15%)",
                                            },
                                            attrs: { slot: "overlay" },
                                            slot: "overlay",
                                          },
                                          [
                                            _c(
                                              "a-menu",
                                              [
                                                _c(
                                                  "a-menu-item",
                                                  {
                                                    key: "HIGH",
                                                    staticStyle: {
                                                      height: "30px",
                                                      "line-height": "30px",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.priorityChange(
                                                          item,
                                                          "高"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "高\n                  "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "a-menu-item",
                                                  {
                                                    key: "MIDDLE",
                                                    staticStyle: {
                                                      height: "30px",
                                                      "line-height": "30px",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.priorityChange(
                                                          item,
                                                          "普通"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "普通\n                  "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "a-menu-item",
                                                  {
                                                    key: "LOW",
                                                    staticStyle: {
                                                      height: "30px",
                                                      "line-height": "30px",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.priorityChange(
                                                          item,
                                                          "低"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "低\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._l(_vm.rejectStages, function (item, idx) {
                              return _c(
                                "a-sub-menu",
                                { key: "submenu" + idx },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        "line-height": "30px",
                                        "min-width": "100%",
                                      },
                                      attrs: { slot: "title" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.priorityChange(
                                            item,
                                            "普通"
                                          )
                                        },
                                      },
                                      slot: "title",
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "statusPointer",
                                        style: {
                                          background:
                                            _vm.statusColorMap.get("REJECTING"),
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-left": "14px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "驳回-" + _vm._s(item.stageName)
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "a-menu-item",
                                    {
                                      key: "HIGH",
                                      staticStyle: { "line-height": "30px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.priorityChange(item, "高")
                                        },
                                      },
                                    },
                                    [_vm._v("高 ")]
                                  ),
                                  _c(
                                    "a-menu-item",
                                    {
                                      key: "MIDDLE",
                                      staticStyle: { "line-height": "30px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.priorityChange(
                                            item,
                                            "普通"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("普通\n          ")]
                                  ),
                                  _c(
                                    "a-menu-item",
                                    {
                                      key: "LOW",
                                      staticStyle: { "line-height": "30px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.priorityChange(item, "低")
                                        },
                                      },
                                    },
                                    [_vm._v("低 ")]
                                  ),
                                ],
                                1
                              )
                            })
                      })
                    : _vm._e(),
                  _vm.rejectStages.length > 1 &&
                  _vm.task.taskStatus !== "FINISHED" &&
                  _vm.task.taskStatus !== "REJECTING" &&
                  _vm.rejectShow
                    ? _c(
                        "a-menu-item",
                        {
                          staticStyle: {
                            "background-color": "#fff !important",
                          },
                        },
                        [
                          _c("div", { staticClass: "task-btn" }, [
                            _c(
                              "div",
                              {
                                staticStyle: { width: "50%" },
                                on: { click: _vm.onCancel },
                              },
                              [_vm._v("\n            取消\n          ")]
                            ),
                            _c(
                              "div",
                              {
                                style: `color:${_vm.color};width:50%`,
                                on: { click: _vm.submitTask },
                              },
                              [_vm._v("确定")]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c("tasks-model", {
        ref: "tasksModel",
        on: { closeTasksModel: _vm.closeTasksModel },
      }),
      _c("term-list-modal", { ref: "termListModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }